import React, { useRef, useEffect } from "react";
import clsx from "clsx";
import { m, AnimatePresence } from "framer-motion";
import { Button, Icon, Image } from "@atoms";
import { useAppState } from "@state";
import { AppLink } from "@base";
import useAllVariantsData from "@shopify/staticQueries/AllVariantsQuery";
import getSubtotal from "@utils/getSubtotal";

const Cart = () => {
  const cartRef = useRef(null);
  const [{ user, cart }, dispatch] = useAppState();
  const { showing } = cart;
  // console.log(user);

  useEffect(() => {
    function handleClickOutside(event) {
      if (cartRef.current && !cartRef.current.contains(event.target)) {
        dispatch({ type: "closeCart" });
      }
    }
    // Bind the event listener
    if (showing) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showing]);

  const allVariants = useAllVariantsData();

  return (
    <AnimatePresence initial>
      {showing && (
        <m.div
          ref={cartRef}
          transition={{ duration: 0.3, type: "tween" }}
          initial={{ opacity: 0, x: "100%" }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: "100%" }}
          className={clsx(
            "fixed right-0 top-10 z-[1000] flex max-h-[calc(100vh_-_4rem)] min-h-1/2-screen w-[20rem] flex-col rounded-l border-y border-l border-orange bg-white shadow-xl md:w-[24rem] lg:w-[28rem]",
            { "pointer-events-none": !showing }
          )}
        >
          <div className="flex flex-shrink-0 border-b border-gray">
            {(user?.level < 2 ||
              (user.customer && user.customer?.id === user.id)) && (
              <h2 className="p-5 font-serif text-2xl">Your Order</h2>
            )}
            {/* practitioner */}
            {user?.level > 2 && (
              <h2 className="p-5 font-serif text-2xl">Your Recommendation</h2>
            )}
            <button
              type="button"
              onClick={() => dispatch({ type: "toggleCart" })}
              className="ml-auto p-4 transition duration-300 hover:text-black"
            >
              <span className="sr-only">toggle cart</span>
              <Icon name="chevron" className="h-4 w-4 text-orange" />
            </button>
          </div>
          <ul className="flex flex-col overflow-auto py-2.5">
            {/* cart items */}
            {cart.items.map(item => {
              const variant = allVariants.find(v => v.storefrontId === item.id);
              return (
                <li
                  key={item.id}
                  className="group flex gap-3 px-5 py-2.5 transition duration-300 hover:bg-offwhite"
                >
                  <AppLink to={variant.url} className="flex flex-grow gap-3 ">
                    <div className="relative h-10 w-10 flex-shrink-0 flex-shrink-0 rounded border border-orange bg-white text-sm md:h-16 md:w-16">
                      <Image image={variant.image} fill objectFit="contain" />
                      <span className="absolute right-0 top-0 z-10 flex h-6 w-6 -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-full bg-green p-1 text-center font-bold text-white">
                        {item.qty}
                      </span>
                    </div>
                    <div className="leading-tight">
                      <h3 className="font-bold">{variant.productTitle}</h3>
                      <p className="text-sm text-black/50">
                        {variant.selectedOptions.reduce(
                          (agg, v) => `${agg} ${v.value}`,
                          ""
                        )}
                      </p>
                      <span className="text-xs font-bold text-black/50">
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(variant.retail * item.qty)}
                      </span>
                    </div>
                  </AppLink>
                  <div className="my-auto ml-auto flex h-4 w-4 flex-shrink-0">
                    <button
                      type="button"
                      className="pointer-events-none h-4 w-4 text-red opacity-0 transition duration-300 group-hover:pointer-events-auto group-hover:opacity-100"
                      onClick={() => {
                        dispatch({ type: "removeCartItem", id: item?.id });
                      }}
                    >
                      <span className="sr-only">remove item</span>
                      <Icon name="close" className="h-4 w-4" />
                    </button>
                  </div>
                </li>
              );
            })}
            <li>
              <AppLink
                to="/products/"
                className="group flex gap-3 px-5 py-2.5 hover:bg-offwhite"
                onClick={() => dispatch({ type: "toggleCart" })}
              >
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded border border-orange bg-gray-light md:h-16 md:w-16">
                  <span className="flex h-4 w-4 items-baseline justify-center rounded-full bg-orange pb-0.5 font-bold leading-none text-white transition duration-300 group-hover:scale-125">
                    +
                  </span>
                </span>
                <span className="font-bold underline decoration-orange decoration-2 underline-offset-2 transition duration-300 group-hover:text-orange">
                  Add a product
                </span>
              </AppLink>
            </li>
          </ul>
          <div className="mx-5 mt-auto flex flex-shrink-0 justify-between gap-5 border-b border-t border-gray py-5">
            <span>Subtotal:</span>{" "}
            <span className="font-bold">
              ${getSubtotal({ cart, user, variants: allVariants })}
            </span>
          </div>
          <div className="flex flex-shrink-0 justify-between gap-5 border-b border-gray p-5 p-5">
            <span>Total:</span>{" "}
            <span className="text-sm font-bold text-orange">
              Tax and shipping calculated at checkout.
            </span>
          </div>
          <div
            className={clsx("flex w-full flex-shrink-0", {
              "flex-col": cart.mode !== "order",
              "flex-col-reverse": cart.mode === "order",
            })}
          >
            {(user.level === 1 || user.level === 2) && (
              <div className="flex flex-col gap-2 border-t border-gray px-5 py-3">
                <Button
                  onClick={() => dispatch({ type: "toggleCart" })}
                  to="/account/orders/new"
                >
                  Checkout
                </Button>
              </div>
            )}
            {user.level > 2 && (
              <>
                {user.customer && user?.customer?.id !== user?.info?.id && (
                  <div className="flex flex-col gap-2 border-t border-gray px-5 py-3">
                    <Button
                      to="/account/orders/new?type=recommendation"
                      onClick={() => dispatch({ type: "toggleCart" })}
                      outline={user.customer && cart.mode === "order"}
                      color={
                        user.customer && cart.mode === "order"
                          ? "black"
                          : "orange"
                      }
                    >{`Recommend to ${user?.customer?.firstName || "First"} ${
                      user?.customer?.lastName || "Last"
                    }`}</Button>
                    <p className="mx-auto max-w-[16rem] text-center text-xs">
                      Review and send this recommendation to this or another
                      client in the next step.
                    </p>
                  </div>
                )}
                <div className="flex flex-col gap-2 border-t border-gray px-5 py-3">
                  <Button
                    onClick={() => dispatch({ type: "toggleCart" })}
                    to="/account/orders/new?type=order"
                    outline={user.customer && cart.mode !== "order"}
                    color={
                      user.customer && cart.mode !== "order"
                        ? "black"
                        : "orange"
                    }
                  >
                    Create An Order
                  </Button>
                  <p className="text-center text-xs">
                    You can send this order to yourself, a client, or drop ship
                    in the next step.
                  </p>
                </div>
              </>
            )}
          </div>
        </m.div>
      )}
    </AnimatePresence>
  );
};

export default Cart;
