import { useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useAppState } from "@state";
import getPrice from "@utils/getPrice";

const query = graphql`
  query allVariants {
    products: allShopifyProduct {
      nodes {
        productId: id
        productTitle: title
        vendor
        url: gatsbyPath(filePath: "/products/{ShopifyProduct.handle}")
        variants {
          id
          storefrontId
          title: displayName
          sku
          inventoryQuantity
          image {
            url: src
            height
            width
          }
          selectedOptions {
            value
            name
          }
          metafields {
            key
            value
          }
          grams: weight
          price
        }
      }
    }
  }
`;

const dataResolver = ({ products }) =>
  products.nodes.reduce(
    (a, p) => [...a, ...p.variants.map(v => ({ ...p, ...v }))],
    []
  );

const useDefaultsData = () => {
  const [{ user }] = useAppState();
  const data = useStaticQuery(query);
  const resolved = useMemo(
    () =>
      dataResolver(data).map(v => ({
        ...v,
        price: v.price,
        retail:
          getPrice(v.price, user.price_mod, v.storefrontId, user.prices) ||
          null,
        inStock: v.inventoryQuantity > 0,
      })),
    [user.price_mod, !!user.prices, user?.customer?.id]
  );
  return resolved;
};

export default useDefaultsData;
