import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useAppState } from "@state";
import { Icon } from "@atoms";
import { AppLink } from "@base";
import { Tooltip } from "@molecules";

const DashboardSidebar = ({ location }) => {
  const [view, setView] = useState(
    location.pathname.split("/")[2] || "account"
  );
  const [{ user }, dispatch] = useAppState();
  const {
    info: { firstName, lastName },
  } = user;

  useEffect(() => {
    setView(location.pathname.split("/")[2] || "account");
  }, [location.pathname]);

  return (
    <div
      className={clsx("flex h-full w-full text-white lg:flex-col lg:gap-3", {
        "bg-orange": user.level === 4,
        "bg-black": user.level > 1 && user.level !== 4 && user.level !== 4,
        "bg-gray-dark": user.level <= 1,
      })}
    >
      <div className="order-1 w-auto shrink-0 p-3 sm:p-5">
        <AppLink to="/">
          <img
            src="/png/logoWhite.png"
            alt="Kan Herb"
            className="h-auto w-32 lg:w-40"
          />
        </AppLink>
      </div>
      <h2 className="order-4 hidden h-0 w-0 p-10 font-bold lg:order-2 lg:block lg:w-auto">
        {firstName} {lastName}
      </h2>
      <nav className="order-3 ml-auto flex justify-start text-xs font-bold lg:m-0 lg:flex-col lg:text-sm">
        <AppLink
          className={clsx(
            "flex items-center gap-2 p-3 transition duration-300 sm:p-5 lg:px-10",
            {
              "hover:bg-green-darker": user.level > 1 && user.level !== 4,
              "hover:bg-orange-dark": user.level <= 1,
              "hover:bg-gray-dark": user.level === 4,
            }
          )}
          to="/products/"
        >
          <Tooltip content="Products" anchor="bottom" className="md:hidden">
            <Icon name="products" className="h-4 w-4" />
          </Tooltip>
          <span className="hidden md:block">Products</span>
        </AppLink>

        {/* orders */}
        {user.level < 4 && (
          <AppLink
            className={clsx(
              "flex items-center gap-2 p-3 transition duration-300 sm:p-5 lg:px-10",
              {
                "hover:bg-green-darker": user.level > 1,
                "bg-green-darker": view === "orders" && user.level > 1,
                "hover:bg-orange-dark": user.level <= 1,
                "bg-orange-dark": view === "orders" && user.level <= 1,
              }
            )}
            to="/account/orders"
          >
            <Tooltip content="Orders" anchor="bottom" className="md:hidden">
              <Icon name="orders" className="h-4 w-4" />
            </Tooltip>
            <span className="hidden md:block">Orders</span>
          </AppLink>
        )}

        {/* customers */}
        {user.level > 2 && user.level !== 4 && (
          <AppLink
            className={clsx(
              "flex items-center gap-2 p-3 transition duration-300 sm:p-5 lg:px-10",
              {
                "hover:bg-green-darker": user.level > 1 && user.level !== 4,
                "bg-green-darker":
                  view === "customers" && user.level > 1 && user.level !== 4,
                "hover:bg-orange-dark": user.level <= 1,
                "bg-orange-dark": view === "customers" && user.level <= 1,
                "hover:bg-gray-dark": user.level === 4,
                "bg-gray-dark": view === "customers" && user.level === 4,
              }
            )}
            to="/account/customers"
          >
            <Tooltip content="Customers" anchor="bottom" className="md:hidden">
              <Icon name="customers" className="h-4 w-4" />
            </Tooltip>
            <span className="hidden md:block">Customers</span>
          </AppLink>
        )}

        {/* todo: change to 3 once earnings is complete */}
        {user.level >= 4 && (
          <AppLink
            className={clsx(
              "flex items-center gap-2 p-3 transition duration-300 sm:p-5 lg:px-10",
              {
                "hover:bg-green-darker": user.level > 1 && user.level !== 4,
                "bg-green-darker":
                  view === "orders" && user.level > 1 && user.level !== 4,
                "hover:bg-orange-dark": user.level <= 1,
                "bg-orange-dark": view === "earnings" && user.level <= 1,
                "hover:bg-gray-dark": user.level === 4,
                "bg-gray-dark": view === "earnings" && user.level === 4,
              }
            )}
            to="/account/earnings"
          >
            <Icon name="barGraph" className="h-5 w-5" />
            <span className="hidden lg:block">Earnings</span>
          </AppLink>
        )}

        {/* account */}
        <AppLink
          className={clsx(
            "flex items-center gap-2 p-3 transition duration-300 sm:p-5 lg:px-10",
            {
              "hover:bg-green-darker": user.level > 1 && user.level !== 4,
              "bg-green-darker":
                view === "account" && user.level > 1 && user.level !== 4,
              "hover:bg-orange-dark": user.level <= 1,
              "bg-orange-dark": view === "account" && user.level <= 1,
              "hover:bg-gray-dark": user.level === 4,
              "bg-gray-dark": view === "account" && user.level === 4,
            }
          )}
          to="/account/"
        >
          <Tooltip content="My Account" anchor="bottom" className="md:hidden">
            <Icon name="cog" className="h-4 w-4" />
          </Tooltip>
          <span className="hidden md:block">My Account</span>
        </AppLink>
        <button
          className={clsx(
            "flex items-center gap-2 p-3 transition duration-300 sm:p-5 lg:px-10",
            {
              "hover:bg-green-darker": user.level > 1 && user.level !== 4,
              "hover:bg-orange-dark": user.level <= 1,
              "hover:bg-gray-dark": user.level === 4,
            }
          )}
          type="button"
          onClick={() => dispatch({ type: "logout" })}
        >
          <Tooltip content="Logout" anchor="bottom" className="md:hidden">
            <Icon name="logout" className="h-4 w-4" />
          </Tooltip>
          <span className="hidden lg:block">Logout</span>
        </button>
      </nav>
    </div>
  );
};

export default DashboardSidebar;
